export function submitSearch(ctrl, callback) {
  ctrl.searchInputTarget.onkeydown = (event) => {
    if (event.keyCode === 13) {
      callback();
    }
  };
}

export function getSearchInputValue(ctrl) {
  return ctrl.searchInputTarget.value;
}
