import { Controller } from 'stimulus';
import Rails from '@rails/ujs';
import * as AgGrid from 'ag-grid-community';
import {
  DEFAULT_GRID_OPTIONS,
  buildColumnDefs,
  GridDatasource,
  updateColumnPositionsInCookies,
} from 'core/utils/grid';

const columnOptions = {
  columnDefs: {
    order_reference: { cellClass: 'font-mono', cellRenderer: 'flexCellRenderer', sortable: false },
    order_date: { cellClass: 'font-mono', maxWidth: 300, sortable: false },
    description: { cellClass: 'font-mono', maxWidth: 300, sortable: false },
    quantity: { cellClass: 'font-mono', maxWidth: 100, sortable: false },
    amount: {
      cellClass: 'ag-right-aligned-cell font-mono', maxWidth: 200, sortable: false,
      headerClass: 'ag-header-cell ag-header-cell-sortable text-xs font-medium text-gray-500 uppercase bg-gray-100'
                   + ' ag-focus-managed ag-right-aligned-header' },
    currency_code: { cellClass: 'font-mono', maxWidth: 150, sortable: false },
    invoice_id: { cellClass: 'font-mono', sortable: false },
    vat: { cellClass: 'font-mono', maxWidth: 100, sortable: false },
    actions: { cellClass: 'ag-right-aligned-cell font-mono', maxWidth: 120, sortable: false, withoutHeader: true }
  },
  cookiesName: 'order_items_columns_positions',
  defaultSorting: [
    'order_reference',
    'order_date',
    'amount',
    'quantity',
    'currency_code',
    'invoice_id',
    'vat',
    'description',
    'actions'
  ],
};

function buildGrid(gridElement) {
  const {
    allowedColumnsJson,
    i18nJson,
    indexUrl,
    paginationPageSize,
  } = gridElement.dataset
  const options = {
    ...DEFAULT_GRID_OPTIONS,
    cacheBlockSize: paginationPageSize,
    maxConcurrentDatasourceRequests: 2,
    columnDefs: buildColumnDefs(columnOptions, {
      allowedColumns: JSON.parse(allowedColumnsJson),
      i18n: JSON.parse(i18nJson),
    }),
    datasource: new GridDatasource({ indexUrl }),
    onColumnMoved(params) { updateColumnPositionsInCookies(columnOptions, params) }
  }
  return AgGrid.createGrid(gridElement, options);
}

export default class extends Controller {
  connect() {
    const gridElement = this.element.querySelector('#orderItemsGrid');

    if (gridElement) {
      this.gridObject = buildGrid(gridElement)
    }
  }


  disconnect() {
    if (this.gridObject) {
      this.gridObject.destroy();
    }
  }
}
