const CREDENTIALS = 'same-origin';

export function httpDelete(url, params = {}) {
  return fetch(url + new URLSearchParams(params), {
    credentials: CREDENTIALS,
    headers: buildHeaders(),
    method: 'DELETE',
  })
}

export function httpGet(url, params = {}) {
  return fetch(url + new URLSearchParams(params), {
    credentials: CREDENTIALS,
    headers: buildHeaders(),
    method: 'GET',
  })
}

export function httpPost(url, body = {}) {
  return fetch(url, {
    body: JSON.stringify(body),
    credentials: CREDENTIALS,
    headers: buildHeaders(),
    method: 'POST',
  })
}

function buildHeaders() {
    return {
      'Accept': 'application/json',
      'Content-Type': 'application/json',
      'X-CSRF-Token': document.querySelector('meta[name="csrf-token"]').content,
    }
}
