import Rails from '@rails/ujs';

export class AllocationsDatasource {
  constructor({limit, callbacks}) {
    this.callbacks = callbacks
    this.filterModel = null
    this.limit = parseInt(limit)
    this.sortModel = null
  }

  getRows(params) {
    const url = this.#buildUrl(params)

    // window.history.replaceState({}, 'search', url)

    // if(this.#isModelChanged(params)) {
    //   window.location.reload()
    // }

    Rails.ajax({
      type: 'GET',
      success: (data, _status, _xhr) => {
        params.successCallback(
          data.records,
          data.next_page ? null : (Math.floor(params.endRow/this.limit) - 1) * this.limit + data.records.length
        )
        this.callbacks.onDataReceived(data)
      },
      url
    });

    this.filterModel = params.filterModel
    this.sortModel = params.sortModel
  }

  #buildUrl(params) {
    // const url = new URL(document.location.pathname, document.location.origin)
    const url = new URL(document.location.href)

    if (this.callbacks.getSearchInput()) {
      url.searchParams.append('search', this.callbacks.getSearchInput())
    }

    Object.entries(params.filterModel).forEach(([field, data]) => {
      Object.entries(data).forEach(([filter, value]) => {
        url.searchParams.set(`${field}[${filter}]`, value)
      })
    })

    params.sortModel.forEach(sort => {
      url.searchParams.append('sort[]', sort.colId)
      url.searchParams.append('sort_order[]', sort.sort)
    })

    url.searchParams.set('limit', this.limit)
    url.searchParams.set('page', this.#isModelChanged(params) ? 1 : this.#nextPage(params))

    return url
  }

  #isModelChanged(params) {
    return this.filterModel != null
      && this.sortModel != null
      && (this.filterModel != params.filterModel)
      && (this.sortModel != params.sortModel)
  }

  #nextPage(params) {
    return Math.ceil(params.endRow/this.limit)
  }
}
