import Rails from '@rails/ujs';
import { DefaultCellRenderer } from 'components/ag_grid_cells/defaultCellRenderer';
import { FlexCellRenderer } from 'components/ag_grid_cells/flexCellRenderer';
import { arrayEquals, arrayMove } from 'core/utils/arrays';
import { getCookie, setCookieForever } from 'core/utils/cookies';

export const DEFAULT_GRID_OPTIONS = {
  defaultColDef: {
    flex: 1,
    resizable: false,
    cellRenderer: 'defaultCellRenderer',
    headerClass:
      'text-xs font-medium text-gray-500 uppercase bg-gray-100',
  },
  components: {
    defaultCellRenderer: DefaultCellRenderer,
    flexCellRenderer: FlexCellRenderer,
  },
  rowBuffer: 0,
  // rowSelection: "multiple",
  // tell grid we want virtual row model type
  rowModelType: 'infinite',
  // how big each page in our page cache will be, default is 100
  pagination: true,
  paginationAutoPageSize: true,
  // how many extra blank rows to display to the user at the end of the dataset,
  // which sets the vertical scroll and then allows the grid to request viewing more rows of data.
  // default is 1, ie show 1 row.
  cacheOverflowSize: 2,
  // how many server side requests to send at a time. if user is scrolling lots, then the requests
  // are throttled down
  maxConcurrentDatasourceRequests: 1,
  // how many rows to initially show in the grid. having 1 shows a blank row, so it looks like
  // the grid is loading from the users perspective (as we have a spinner in the first col)
  infiniteInitialRowCount: 100,
  // how many pages to store in cache. default is undefined, which allows an infinite sized cache,
  // pages are never purged. this should be set for large data to stop your browser from getting
  // full of data
  maxBlocksInCache: 10,
  // debug: true,
  onCellClicked(event) {
    const detailsPath = event.data.details_path;
    if (detailsPath) { window.location.assign(detailsPath) }
  }
}

// Arguments:
//   opts:
//    indexUrl(String, required) e.g. /payments
//    exportLinkElement(Element) e.g. element.querySelector('.export-transactions-link')
//
export class GridDatasource {
  constructor({indexUrl, exportLinkElement}) {
    this.exportLinkElement = exportLinkElement;
    this.indexUrl = indexUrl;
    this.page = 1;
    this.prevSortModel = null;
    this.recordsCount = 0;
  }

  getRows(params) {
    const sortModel = params.sortModel[0];
    let indexUrl = new URL(this.indexUrl, "http://example.com");

    if (sortModel) {
      indexUrl.searchParams.set('sort_column', sortModel.colId);
      indexUrl.searchParams.set('sort_direction', sortModel.sort);

      if (this.exportLinkElement) {
        const exportUrl = new URL(this.exportLinkElement.href);
        exportUrl.searchParams.set('sort_column', sortModel.colId);
        exportUrl.searchParams.set('sort_direction', sortModel.sort);
        this.exportLinkElement.href = exportUrl;
      }
    }

    // Sorting is applied. Reset variables
    if ((this.prevSortModel && !sortModel)
      || (!this.prevSortModel && sortModel)
      || this.prevSortModel != sortModel
    ) {
      this.page = 1;
      this.recordsCount = 0;
    }

    indexUrl.searchParams.set('page', this.page);

    Rails.ajax({
      type: 'GET',
      url: `${indexUrl.pathname}${indexUrl.search}`,
      success: (data, _status, _xhr) => {
        const { records, next_page } = data;
        this.recordsCount += records.length;
        const lastRow = next_page ? -1 : this.recordsCount;

        params.successCallback(records, lastRow);

        this.page += 1;
        this.prevSortModel = sortModel;
      },
    });
  }
}

export function buildColumnDefs(columnPositionOpts, {allowedColumns, i18n}) {
  const columnDefs = columnPositionOpts.columnDefs;
  const columnPositions = loadColumnPositions(columnPositionOpts)
    .filter(column => allowedColumns.includes(column));

  return columnPositions.map((name) => {
    const columnDef = columnDefs[name];
    if (!columnDef) return;

    columnDef.field = name;
    columnDef.headerName = columnDef.withoutHeader ? '' : i18n[name];
    delete columnDef.withoutHeader;

    return columnDef;
  });
}

function loadColumnPositions(columnPositionOpts) {
  let columnPositions = [];

  if (getCookie(columnPositionOpts.cookiesName)) {
    columnPositions = JSON.parse(getCookie(columnPositionOpts.cookiesName));

    // for the case if we change fields
    if (!arrayEquals(columnPositions, columnPositionOpts.defaultSorting)) {
      columnPositions = columnPositionOpts.defaultSorting;
    }
  } else {
    columnPositions = columnPositionOpts.defaultSorting;
    storeColumnPositionsInCookies(columnPositionOpts, columnPositions);
  }

 	return columnPositions;
}

function storeColumnPositionsInCookies(columnPositionOpts, columnPositions) {
  setCookieForever(columnPositionOpts.cookiesName, JSON.stringify(columnPositions));
}

export function updateColumnPositionsInCookies(columnPositionOpts, params) {
  const columnPositions = loadColumnPositions(columnPositionOpts);
  const fromIndex = columnPositions.indexOf(params.column.colId);
  arrayMove(columnPositions, fromIndex, params.toIndex);
  storeColumnPositionsInCookies(columnPositionOpts, columnPositions);
}
