import { Controller } from 'stimulus';
import Rails from '@rails/ujs';

import * as AgGrid from 'ag-grid-community';

import {
  DEFAULT_GRID_OPTIONS,
  buildColumnDefs,
  GridDatasource,
  updateColumnPositionsInCookies,
} from 'core/utils/grid';

// TODO: move to core
class RedirectCell {
  init(params) {
    this.eGui = document.createElement('div');
    this.eGui.innerHTML = (params.value !== null && params.value !== undefined) ? params.value : '';

    const links = this.eGui.querySelectorAll('a');
    links.forEach(link => {
      link.addEventListener('click', (e) => {
        e.stopPropagation(); // Prevent triggering the grid's onCellClicked
        Rails.visit(link.getAttribute('href'));
      });
    });
  }

  getGui() {
    return this.eGui;
  }
}

const columnOptions = {
  columnDefs: {
    event: { cellClass: 'font-mono cursor-pointer', sortable: false },
    item: { cellClass: 'font-mono cursor-pointer', cellRenderer: 'flexCellRenderer', sortable: false },
    user: { cellClass: 'font-mono cursor-pointer', sortable: false },
    executed: { cellClass: 'font-mono cursor-pointer', sortable: false },
    item_link: { cellClass: 'font-mono cursor-pointer', cellRenderer: 'enhancedCellRenderer', sortable: false },
  },
  cookiesName: 'audit_trails_columns_positions',
  defaultSorting: [
    'event',
    'item',
    'user',
    'executed',
    'item_link'
  ],
};

function buildGrid(gridElement) {
  const {
    allowedColumnsJson,
    i18nJson,
    indexUrl,
    paginationPageSize,
  } = gridElement.dataset;
  const options = {
    ...DEFAULT_GRID_OPTIONS,
    cacheBlockSize: paginationPageSize,
    maxConcurrentDatasourceRequests: 2,
    columnDefs: buildColumnDefs(columnOptions, {
      allowedColumns: JSON.parse(allowedColumnsJson),
      i18n: JSON.parse(i18nJson),
    }),
    datasource: new GridDatasource({ indexUrl }),
    onColumnMoved(params) { updateColumnPositionsInCookies(columnOptions, params) },
    // Dynamically include your enhanced cell renderer
    components: {
      ...DEFAULT_GRID_OPTIONS.components, // Include existing components
      enhancedCellRenderer: RedirectCell // Add the enhanced cell renderer
    },
  };
  return AgGrid.createGrid(gridElement, options);
}

export default class extends Controller {
  connect() {
    const gridElement = this.element.querySelector('#auditTrailsGrid');

    if (gridElement) {
      this.gridObject = buildGrid(gridElement);
    }
  }

  disconnect() {
    if (this.gridObject) {
      this.gridObject.destroy();
    }
  }
}
