export const DEFAULT_PAYMENT_METHOD = 'CHEQUE'

// DISPLAY --------------------------------------------------------------------
export const DEFAULT_COL_DEF = {
  cellClass: ['font-mono'],
  cellStyle: {height: '39px'}
}

export const AMOUNT_COL_DEF = {
  ...DEFAULT_COL_DEF,
  cellClass: ['ag-right-aligned-cell', ...DEFAULT_COL_DEF.cellClass],
  cellDataType: 'number',
  cellRenderer(params) {
    if (params.data && params.value) {
      return formatAmount(params.value, params.data.currency_code)
    }
  },
}

export const STATUS_COL_DEF = {
  ...DEFAULT_COL_DEF,
  cellRenderer: 'defaultCellRenderer',
}

// EDIT -----------------------------------------------------------------------
// https://www.ag-grid.com/javascript-data-grid/reference-data/#using-the-refdata-property
export function buildPaymentMethodEditableColDef(paymentMethods) {
  return {
    ...DEFAULT_COL_DEF,
    cellEditor: 'agSelectCellEditor',
    cellEditorParams: {
      values: Object.keys(paymentMethods),
      useFormatter: true
    },
    editable: true,
    refData: paymentMethods,
    cellRenderer(params) {
      return paymentMethods[params.value]
    },
  //   onCellValueChanged: function(params) {
  //     if (params.newValue !== params.oldValue) {
  //       // Force the grid to refresh the editability status of the `cheque_number` column
  //       params.api.refreshCells({
  //         rowNodes: [params.node],
  //         columns: ['cheque_number'],
  //         force: true
  //       });
  //     }
  //   }
  };
}

export const EDITABLE_AMOUNT_COL_DEF = {
  ...AMOUNT_COL_DEF,
  cellEditor: 'agNumberCellEditor',
  cellEditorParams: {
    // max: 10000000,
    // min: 0.01,
    precision: 2,
    showStepperButtons: true,
    step: 0.01
  },
  editable: true,
}

export const EDITABLE_DATE_COL_DEF = {
  ...DEFAULT_COL_DEF,
  cellDataType: 'dateString',
  cellEditor: 'agDateStringCellEditor',
  cellEditorParams: {
    min: formatDate(addDays(new Date(), -180)),
    max: formatDate(new Date())
  },
  editable: true,
}

export const EDITABLE_CHEQUE_COL_DEF = {
  ...DEFAULT_COL_DEF,
  ...EDITABLE_TEXT_COL_DEF,
  editable(params) {
    return params.data.payment_method === DEFAULT_PAYMENT_METHOD;
  },
}

export const EDITABLE_TEXT_COL_DEF = {
  ...DEFAULT_COL_DEF,
  cellDataType: 'text',
  cellEditor: 'agTextCellEditor',
  cellEditorParams: { maxLength: 250 },
  editable: true,
}

export const EDITABLE_ACCOUNT_NUMBER_COL_DEF = {
  ...DEFAULT_COL_DEF,
  ...EDITABLE_TEXT_COL_DEF,
  editable(params) {
    return !!params.node.rowPinned
  }
}

// FILTERS --------------------------------------------------------------------

export const FILTER_TRANSLATIONS = {
  empty: ' '
}

// const DEFAULT_FILTER_COL_DEF = {
//   floatingFilter: true
// }

const DEFAULT_FILTER_PARAMS = {
  buttons: ['reset', 'apply'],
  closeOnApply: true,
  maxNumConditions: 1
}

export const AMOUNT_FILTER_COL_DEF = {
  filter: 'agNumberColumnFilter',
  filterParams: {
    ...DEFAULT_FILTER_PARAMS,
    filterOptions: ['inRange', 'lessThan', 'greaterThan']
  },
}
export const DATE_FILTER_COL_DEF = {
  filter: 'agDateColumnFilter',
  filterParams: {
    ...DEFAULT_FILTER_PARAMS,
    filterOptions: ['inRange', 'lessThan', 'greaterThan'],
    maxValidDate: formatDate(new Date())
  }
}
export const TEXT_FILTER_COL_DEF = {
  filter: 'agTextColumnFilter',
  filterParams: {
    ...DEFAULT_FILTER_PARAMS,
    filterOptions: ['contains'],
  },
}

export function buildEnumFilterColDef(valuesMap) {
  const entries = Object.entries(valuesMap)
  return {
    filter: 'agTextColumnFilter',
    filterParams: {
      ...DEFAULT_FILTER_PARAMS,
      filterOptions: [
        "empty",
        ...entries.map(([displayKey, displayName]) => {
          return {
              displayKey,
              displayName,
              predicate(_, _cellValue) {
                return true
              },
              numberOfInputs: 0
          }
        })
      ],
      // maxNumConditions: entries.length
    },
  }
}

// UTILS ----------------------------------------------------------------------
export function formatAmount(amount, currencyCode) {
  return `${currencyCode} ${new Intl.NumberFormat("en-EN", { minimumFractionDigits: 2, }).format(amount)}`
}

export function formatDate(date) {
  return date.toJSON().slice(0, 10);
}

function addDays(date, days) {
  date.setDate(date.getDate() + days);
  return date;
}
