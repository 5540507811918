import { Controller } from 'stimulus';
import Rails from '@rails/ujs';

import * as AgGrid from 'ag-grid-community';

import {
  DEFAULT_GRID_OPTIONS,
  GridDatasource,
  buildColumnDefs,
  updateColumnPositionsInCookies,
} from 'core/utils/grid';

var columnOptions = {
  columnDefs: {
    amount_html: {
      cellClass: 'font-mono cursor-pointer text-right',
      headerClass: 'ag-header-cell ag-header-cell-sortable text-xs font-medium text-gray-500 uppercase bg-gray-100'
                   + ' ag-focus-managed ag-right-aligned-header',
      maxWidth: 200,
      sortable: true,
    },
    created_at: { cellClass: 'font-mono cursor-pointer', sortable: true },
    fingerprint: { cellClass: 'font-mono cursor-pointer', maxWidth: 150 },
    payment_instrument_html: {
      cellClass: 'p-0 mt-2 cursor-pointer', maxWidth: 25, sortable: true, withoutHeader: true,
    },
    payment_status_html: { cellClass: 'font-mono cursor-pointer', maxWidth: 150, sortable: true },
    processing_status_html: { cellClass: 'font-mono cursor-pointer', sortable: true },
    refunds_text: { cellClass: 'font-mono cursor-pointer', maxWidth: 200, sortable: true },
    status_html: { cellClass: 'cursor-pointer', maxWidth: 55, sortable: true, withoutHeader: true },
    updated_at: { cellClass: 'font-mono cursor-pointer', sortable: true },
  },
  defaultSorting: [
    'status_html',
    'payment_instrument_html',
    'updated_at',
    'created_at',
    'fingerprint',
    'payment_status_html',
    'processing_status_html',
    'amount_html',
    'refunds_text',
  ],
};

const COOKIES_CHECKSUM = JSON.stringify(columnOptions.defaultSorting).length;
columnOptions.cookiesName = `payments_columns_positions_${COOKIES_CHECKSUM}`;

function buildGrid(gridElement) {
  const {
    allowedColumnsJson,
    i18nJson,
    indexUrl,
    paginationPageSize,
  } = gridElement.dataset
  const options = {
    ...DEFAULT_GRID_OPTIONS,
    columnDefs: buildColumnDefs(columnOptions, {
      allowedColumns: JSON.parse(allowedColumnsJson),
      i18n: JSON.parse(i18nJson)
    }),
    datasource: new GridDatasource({ indexUrl }),
    onColumnMoved(params) {
      updateColumnPositionsInCookies(columnOptions, params)
    },
    paginationPageSize
  }
  return AgGrid.createGrid(gridElement, options);
}

export default class extends Controller {
  connect() {
    const gridElement = this.element.querySelector('#paymentsGrid');

    if (gridElement) {
      this.gridObject = buildGrid(gridElement)
    }
  }

  disconnect() {
    if (this.gridObject) {
      this.gridObject.destroy();
    }
  }
}
