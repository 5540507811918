import { Controller } from 'stimulus';
import * as AgGrid from 'ag-grid-community';

import {
  DEFAULT_GRID_OPTIONS,
  buildColumnDefs,
  updateColumnPositionsInCookies,
} from 'core/utils/grid';

import {
  httpDelete,
  httpGet,
  httpPost,
} from './http'

import {
  AMOUNT_COL_DEF,
  AMOUNT_FILTER_COL_DEF,
  DATE_FILTER_COL_DEF,
  DEFAULT_COL_DEF,
  DEFAULT_PAYMENT_METHOD,
  EDITABLE_ACCOUNT_NUMBER_COL_DEF,
  EDITABLE_AMOUNT_COL_DEF,
  EDITABLE_CHEQUE_COL_DEF,
  EDITABLE_DATE_COL_DEF,
  EDITABLE_TEXT_COL_DEF,
  FILTER_TRANSLATIONS,
  STATUS_COL_DEF,
  TEXT_FILTER_COL_DEF,
  buildEnumFilterColDef,
  buildPaymentMethodEditableColDef,
  formatAmount,
  formatDate,
} from './allocations_columns'

import {
  AllocationsDatasource
} from './allocations_datasource'

import {
  submitSearch,
  getSearchInputValue
} from './search_utilities'

const CANCEL_ACTION = 'cancel'
const DISCARD_ACTION = 'discard'
const EDIT_ACTION = 'edit'
const PROVISION_ACTION = 'provision'
const HIDDEN_CLASS = 'hidden'

function toggleButtons($agCell, editing) {
  const editActions = [CANCEL_ACTION, PROVISION_ACTION]
  const listActions = [EDIT_ACTION, DISCARD_ACTION]
  const hideActions = editing ? listActions : editActions
  const showActions = editing ? editActions : listActions

  hideActions.forEach(action =>
    $agCell.querySelectorAll(`[data-action="${action}"]`).forEach(el => el.classList.add(HIDDEN_CLASS))
  )
  showActions.forEach(action =>
    $agCell.querySelectorAll(`[data-action="${action}"]`).forEach(el =>  {
      el.classList.remove(HIDDEN_CLASS)
      el.disabled = false
    })
  )
}
function hideButtons($agCell) {
  $agCell.querySelectorAll('button').forEach(el => el.classList.add(HIDDEN_CLASS))
}

const OPEN_STATUS = 'OPEN'

function handleCancel(params) {
  params.api.stopEditing()
}

async function handleDiscard(params) {
  const response = await httpDelete(params.data.discard_path)
  const data = await response.json()

  if (response.ok) {
    params.api.purgeInfiniteCache()
  } else {
    alert(data.error)
  }
}

function handleEdit(params) {
  params.data.payment_amount ||= params.data.due_amount
  params.data.payment_date ||= formatDate(new Date())
  params.data.payment_method ||= DEFAULT_PAYMENT_METHOD
  params.api.startEditingCell({ rowIndex: params.node.rowIndex, colKey: "payment_method" })
  // toggleButtons(params.event.target.closest('.ag-cell'), true)
  toggleButtons(actionsCell(params), true)
}

async function handleProvision(params) {
  params.api.stopEditing();
  const body = {
    allocation:  {
      account_number: params.data.account_number,
      cheque_number: params.data.cheque_number,
      comment: params.data.comment,
      invoice_number: params.data.invoice_number,
      payment_amount: params.data.payment_amount,
      payment_date: params.data.payment_date,
      payment_method: params.data.payment_method
    }
  }
  const response = await httpPost(params.data.provision_path, body, body)
  const data = await response.json()

  if (response.ok) {
    params.api.purgeInfiniteCache()
  } else {
    alert(data.error)
  }
}

function actionsCell(params) {
  return params.api.getCellRendererInstances({columns: ['actions'], rowNodes: [params.node]})[0]?.eGui
}

function isEditing(params) {
  return params.api.getEditingCells().length > 0
}

function isRowPinned(params) {
  return !!params.node.rowPinned
}

async function addAllocation(api, url) {
  const response = await httpGet(url)
  const data = await response.json()

  if (response.ok) {
    api.setGridOption('pinnedTopRowData', [data])
    api.startEditingCell({rowIndex: 0, rowPinned: 'top', colKey: 'account_number'})
    toggleButtons(document.querySelector('.ag-row-pinned div[col-id="actions"]'), true)
  } else {
    alert(data.error)
  }
}

const columnOptions = {
  columnDefs: {
    account_number: { ...EDITABLE_ACCOUNT_NUMBER_COL_DEF, ...TEXT_FILTER_COL_DEF, maxWidth: 300, sortable: true },
    actions: {
      ...DEFAULT_COL_DEF,
      cellClass: ['ag-right-aligned-cell', ...DEFAULT_COL_DEF.cellClass],
      cellRenderer: 'defaultCellRenderer',
      maxWidth: 125,
      minWidth: 125,
      onCellClicked(params) {
        const $target = params.event.target
        if (!$target) {
          return
        }
        const $button = ($target.nodeType == 'BUTTON') ? $target : $target.closest('button')

        if (!$button) {
          return
        }

        switch($button.dataset['action']) {
          case CANCEL_ACTION: return handleCancel(params)
          case DISCARD_ACTION: return handleDiscard(params)
          case EDIT_ACTION: return handleEdit(params)
          case PROVISION_ACTION: return handleProvision(params)
        }
      },
      sortable: false,
      withoutHeader: true
    },
    cheque_number: { ...EDITABLE_CHEQUE_COL_DEF, ...TEXT_FILTER_COL_DEF, maxWidth: 300, sortable: true },
    comment: { ...EDITABLE_TEXT_COL_DEF, maxWidth: 300, sortable: false, },
    company_name: { ...DEFAULT_COL_DEF, ...TEXT_FILTER_COL_DEF, maxWidth: 300, sortable: true },
    due_amount: { ...AMOUNT_COL_DEF, ...AMOUNT_FILTER_COL_DEF, minWidth: 170, sortable: true },
    due_date: { ...DEFAULT_COL_DEF, ...DATE_FILTER_COL_DEF, maxWidth: 300, sortable: true, sort: 'desc' },
    invoice_number: { ...DEFAULT_COL_DEF, ...TEXT_FILTER_COL_DEF, maxWidth: 300, sortable: true },
    payment_amount: { ...EDITABLE_AMOUNT_COL_DEF, ...AMOUNT_FILTER_COL_DEF, minWidth: 170, sortable: true },
    payment_date: { ...EDITABLE_DATE_COL_DEF, ...DATE_FILTER_COL_DEF, maxWidth: 300, sortable: true, },
    payment_method: { ...DEFAULT_COL_DEF, maxWidth: 300, sortable: true },
    status: { ...STATUS_COL_DEF, minWidth: 125, maxWidth: 150, sortable: true },
    user_name: { ...DEFAULT_COL_DEF, maxWidth: 300, sortable: false },
  },
  cookiesName: 'allocations_columns_positions',
  defaultSorting: [
    'status',
    'due_date',
    'account_number',
    'company_name',
    'due_amount',
    'invoice_number',
    'payment_method',
    'payment_date',
    'payment_amount',
    'cheque_number',
    'user_name',
    'comment',
    'actions'
  ],
};

function buildGrid($grid, callbacks) {
  const {
    allowedColumnsJson,
    i18nJson,
    newAllocationUrl,
    paginationPageSize,
    paymentMethodsJson,
    statusesJson,
  } = $grid.dataset

  columnOptions.columnDefs.payment_method = {
    ...columnOptions.columnDefs.payment_method,
    ...buildPaymentMethodEditableColDef(JSON.parse(paymentMethodsJson)),
    ...buildEnumFilterColDef(JSON.parse(paymentMethodsJson))
  }
  columnOptions.columnDefs.status = {
    ...columnOptions.columnDefs.status,
    ...buildEnumFilterColDef(JSON.parse(statusesJson))
  }

  const i18nHeaders = JSON.parse(i18nJson)

  Object.entries(columnOptions.columnDefs).forEach(([colName, colDef]) => {
    if (i18nHeaders[colName]) colDef.headerTooltip = i18nHeaders[colName]
  })

  const options = {
    ...DEFAULT_GRID_OPTIONS,
    // override defaults
    defaultColDef: {
      flex: 1,
      headerClass: 'text-xs font-medium text-gray-500 uppercase bg-gray-100',
      resizable: false,
      suppressKeyboardEvent(params) {
        if (params.event.code == 'ArrowDown' || params.event.code == 'ArrowUp') {
          return false
        }

        if (params.event.code == 'Enter') {
          params.editing ? handleProvision(params) : handleEdit(params)
        }

        if (params.event.code == 'Escape' && params.editing) {
          handleCancel(params)
        }

        return true
      },
    },
		// animateRows: true,
    columnDefs: buildColumnDefs(columnOptions, {
      allowedColumns: JSON.parse(allowedColumnsJson),
      i18n: i18nHeaders
    }),
    localeText: {
      ...FILTER_TRANSLATIONS
    },
    datasource: new AllocationsDatasource({ limit: paginationPageSize, callbacks }),
    // editing
    editType: "fullRow",
    suppressClickEdit: true,
    // suppressRowClickSelection: true,
    // pagination
    cacheBlockSize: paginationPageSize,
    cacheOverflowSize: 0,
    maxBlocksInCache: 1,
    blockLoadDebounceMillis: 100,
    // events
    onCellEditingStopped(params) {
      if (isRowPinned(params)) {
        params.api.setGridOption('pinnedTopRowData', [])
      } else {
        params.node.setDataValue(params.column.colId, params.data.status_orig == OPEN_STATUS ? null : params.oldValue)
      }
    },
    onCellMouseOver(params) {
      if (!isEditing(params)) {
        toggleButtons(actionsCell(params), false)
      }
    },
    onCellMouseOut(params) {
      if (!isEditing(params)) {
        hideButtons(actionsCell(params))
      }
    },
    onColumnMoved(params) {
      updateColumnPositionsInCookies(columnOptions, params)
    },
    onRowDoubleClicked(params) {
      if (!isEditing(params)) {
        handleEdit(params)
      }
    },
    onRowEditingStopped(params) {
      if (actionsCell(params)) {
        hideButtons(actionsCell(params))
      }
    },
    onSortChanged(params) {
      params.api.paginationGoToPage(0)
      params.api.purgeInfiniteCache()
    },
    // apply filter/sort from params
    // onGridReady(params) {
    //   // default sorting
    //   const DEFAULT_SORTING = [{colId: 'due_date', sort: 'desc'}]
    //   params.api.applyColumnState({state: DEFAULT_SORTING, defaultState: { sort: null}})
    // },
  }
  const gridApi = AgGrid.createGrid($grid, options);

  callbacks.onNewAllocation(() => addAllocation(gridApi, newAllocationUrl))
  callbacks.onSearchSubmit(() => gridApi.onFilterChanged())

  return gridApi
}

export default class extends Controller {
  static classes = [
    'badTotalPaymentAmount',
    'okTotalPaymentAmount',
  ];

  static targets = [
    'grid',
    'searchInput',
    'totalDueAmount',
    'totalPaymentAmount',
    'totalPaymentAmountText',
  ];

  connect() {
    const ctrl = this

    if (this.hasGridTarget) {
      const $addAllocationBtn = document.querySelector('.add-allocation')
      const $createBookingBtn = document.querySelector('.create-booking')

      this.gridObject = buildGrid(this.gridTarget, {
        getSearchInput() {
          return getSearchInputValue(ctrl)
        },
        onDataReceived(response) {
          if (response.currency_code != undefined) {
            ctrl.setTotalAmounts(response.due_amount, response.payment_amount, response.currency_code)
          }
          if (response.provisional_count != undefined) {
            ctrl.toggleVisibilty($createBookingBtn, response.provisional_count > 0)
          }
        },
        onNewAllocation(callback) {
          if ($addAllocationBtn) {
            $addAllocationBtn.onclick = callback
          }
        },
        onSearchSubmit(callback) {
          submitSearch(ctrl, callback)
        },
      });
    }
  }

  disconnect() {
    if (this.gridObject) {
      this.gridObject.destroy();
    }
  }

  // custom

  setTotalAmounts(dueAmount, paymentAmount, currencyCode) {
    this.totalPaymentAmountTarget.innerText = formatAmount(paymentAmount, currencyCode)
    this.totalDueAmountTarget.innerText = formatAmount(dueAmount, currencyCode)

    this.totalPaymentAmountTextTarget.classList.remove(this.badTotalPaymentAmountClasses)
    this.totalPaymentAmountTextTarget.classList.remove(this.okTotalPaymentAmountClasses)
    this.totalPaymentAmountTextTarget.classList.add(
      paymentAmount == dueAmount ? this.okTotalPaymentAmountClasses : this.badTotalPaymentAmountClasses
    )
  }

  toggleVisibilty(el, isVisible) {
    isVisible ? el.classList.remove(HIDDEN_CLASS) : el.classList.add(HIDDEN_CLASS)
  }
}
