import { Controller } from 'stimulus';
import * as AgGrid from 'ag-grid-community';

import {
  DEFAULT_GRID_OPTIONS,
  buildColumnDefs,
  updateColumnPositionsInCookies,
} from 'core/utils/grid';

import {
  AMOUNT_COL_DEF,
  AMOUNT_FILTER_COL_DEF,
  DATE_FILTER_COL_DEF,
  DEFAULT_COL_DEF,
  STATUS_COL_DEF,
  TEXT_FILTER_COL_DEF,
  buildEnumFilterColDef,
  formatPaymentMethod,
} from './allocations_columns'

import {
  AllocationsDatasource
} from './allocations_datasource'

import {
  submitSearch,
  getSearchInputValue
} from './search_utilities'

const HIDDEN_CLASS = 'hidden'

const columnOptions = {
  columnDefs: {
    account_number: { ...DEFAULT_COL_DEF, ...TEXT_FILTER_COL_DEF, maxWidth: 300, sortable: true },
    actions: {
      ...DEFAULT_COL_DEF,
      cellClass: ['ag-right-aligned-cell', ...DEFAULT_COL_DEF.cellClass],
      cellRenderer: 'defaultCellRenderer',
      maxWidth: 125,
      minWidth: 125,
      sortable: false,
      withoutHeader: true
    },
    cheque_number: { ...DEFAULT_COL_DEF, ...TEXT_FILTER_COL_DEF, maxWidth: 300, sortable: true },
    comment: { ...DEFAULT_COL_DEF, maxWidth: 300, sortable: false, },
    company_name: { ...DEFAULT_COL_DEF, ...TEXT_FILTER_COL_DEF, maxWidth: 300, sortable: true },
    due_amount: { ...AMOUNT_COL_DEF, ...AMOUNT_FILTER_COL_DEF, minWidth: 170, sortable: true },
    due_date: { ...DEFAULT_COL_DEF, ...DATE_FILTER_COL_DEF, maxWidth: 300, sortable: true, sort: 'desc' },
    invoice_number: { ...DEFAULT_COL_DEF, ...TEXT_FILTER_COL_DEF, maxWidth: 300, sortable: true },
    payment_amount: { ...AMOUNT_COL_DEF, ...AMOUNT_FILTER_COL_DEF, minWidth: 170, sortable: true },
    payment_date: { ...DEFAULT_COL_DEF, ...DATE_FILTER_COL_DEF, maxWidth: 300, sortable: true, },
    payment_method: {
      ...DEFAULT_COL_DEF,
      maxWidth: 300,
      sortable: true,
    },
    status: { ...STATUS_COL_DEF, minWidth: 125, maxWidth: 150, sortable: true },
    user_name: { ...DEFAULT_COL_DEF, maxWidth: 300, sortable: false },
  },
  cookiesName: 'allocations_columns_positions',
  defaultSorting: [
    'status',
    'due_date',
    'account_number',
    'company_name',
    'due_amount',
    'invoice_number',
    'payment_method',
    'payment_date',
    'payment_amount',
    'cheque_number',
    'user_name',
    'comment',
    'actions'
  ],
};

function buildGrid($grid, callbacks) {
  const {
    allowedColumnsJson,
    i18nJson,
    paginationPageSize,
    paymentMethodsJson,
    statusesJson,
  } = $grid.dataset

  const paymentMethods = JSON.parse(paymentMethodsJson)

  columnOptions.columnDefs.payment_method = {
    ...columnOptions.columnDefs.payment_method,
    valueFormatter: (params) => paymentMethods[params.value] || params.value,
    ...buildEnumFilterColDef(paymentMethods)
  }

  columnOptions.columnDefs.status = {
    ...columnOptions.columnDefs.status,
    ...buildEnumFilterColDef(JSON.parse(statusesJson))
  }

  const i18nHeaders = JSON.parse(i18nJson)

  Object.entries(columnOptions.columnDefs).forEach(([colName, colDef]) => {
    if (i18nHeaders[colName]) colDef.headerTooltip = i18nHeaders[colName]
  })

  const options = {
    ...DEFAULT_GRID_OPTIONS,
    // override defaults
    defaultColDef: {
      flex: 1,
      headerClass: 'text-xs font-medium text-gray-500 uppercase bg-gray-100',
      resizable: false,
    },
    columnDefs: buildColumnDefs(columnOptions, {
      allowedColumns: JSON.parse(allowedColumnsJson),
      i18n: i18nHeaders
    }),
    datasource: new AllocationsDatasource({ limit: paginationPageSize, callbacks }),
    // pagination
    cacheBlockSize: paginationPageSize,
    cacheOverflowSize: 0,
    maxBlocksInCache: 1,
    blockLoadDebounceMillis: 100,
    // events
    onColumnMoved(params) {
      updateColumnPositionsInCookies(columnOptions, params)
    },
    onSortChanged(params) {
      params.api.paginationGoToPage(0)
      params.api.purgeInfiniteCache()
    },
  }
  const gridApi = AgGrid.createGrid($grid, options);

  callbacks.onSearchSubmit(() => gridApi.onFilterChanged())

  return gridApi
}

export default class extends Controller {
  static targets = [
    'grid',
    'searchInput',
  ];

  connect() {
    const ctrl = this

    if (this.hasGridTarget) {
      this.gridObject = buildGrid(
        this.gridTarget,
        {
          getSearchInput() {
            return getSearchInputValue(ctrl)
          },
          onSearchSubmit(callback) {
            submitSearch(ctrl, callback)
          },
          // blank function to avoid errors
          onDataReceived: () => { },
        }
      )
    }
  }

  disconnect() {
    if (this.gridObject) {
      this.gridObject.destroy();
    }
  }

  toggleVisibilty(el, isVisible) {
    isVisible ? el.classList.remove(HIDDEN_CLASS) : el.classList.add(HIDDEN_CLASS)
  }
}
